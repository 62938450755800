import React, { useEffect } from "react";
import useCreateUserFunction from "./createUserFunction";
import { toast } from "react-toastify";
import "./style.css";

const CreateUser = () => {
  const {
    username,
    setUsername,
    password,
    setPassword,
    handleCreateUser,
    error,
    success,
    setSuccess,
    setError,
  } = useCreateUserFunction();

  useEffect(() => {
    if (success) {
      toast.success(success, { autoClose: 3000 });
      // Clear the success message after showing the toast
      setSuccess(null);
    }
    if (error) {
      toast.error(error, { autoClose: 3000 });
      // Clear the error message after showing the toast
      setError(null);
    }
  }, [success, error]);

  return (
    <div className="create-user-container">
      <form className="create-user-form" onSubmit={handleCreateUser}>
        <h2>Create New User</h2>
        <div>
          <label>Username:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Create User</button>
      </form>
    </div>
  );
};

export default CreateUser;
