import React from "react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "./style.css";


const sidebarItems = [
  { path: "/", label: "Home" },
  { path: "/create-patient", label: "Create New Patient" },
  { path: "/override-form", label: "Override" },
  { path: "/xml-validation", label: "Xml Validation" },
];

const Sidebar = () => {
  return (
    <div className="sidebar">
      <div className="sidebar-header">Menu</div>
      <Nav className="flex-column">
        {sidebarItems?.map((item, index) => (
          <Nav.Item key={index}>
            <Nav.Link
              as={NavLink}
              to={item.path}
              className="nav-link"
              activeClassName="active"
            >
              {item.label}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
    </div>
  );
};

export default Sidebar;
