import React from "react";
import { Container, Form, Button, Row, Col, Alert, Spinner } from "react-bootstrap";
import "./xmlValidationFile.css";
import XmlValidationFileFunction from "./xmlValidationFileFunction";

const XmlValidationFile = () => {
  const {
    loading,
    error,
    clearError,
    handleSubmit,
    fileError,
    fileName,
    uploading,
    uploadProgress,
    handleFileChange,
    successMessage,
    clearSuccessMessage,
    errorMessages,
    handleSubmit1
  } = XmlValidationFileFunction();

  return (
    <Container className="mb-5 modify">
      {uploading && (
        <div className="loading-overlay">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <div className="progress-container mt-3">
            <div
              className="progress-bar"
              style={{ width: `${uploadProgress}%` }}
            />
            <div className="progress-text" style={{ fontSize: '2rem' }}>{uploadProgress}%</div> {/* Larger font size */}
          </div>
        </div>
      )}

      {/* Form for Upload */}
      <Form className="mt-5 custom-form" onSubmit={handleSubmit}>
            <h1>XML File Validation</h1>
            {/* File Upload and Submit Button on the Same Line */}
        <Form.Group as={Row} controlId="formFile" className="mt-2">
          <Form.Label column sm="2" className="form-label">
            Upload File:
          </Form.Label>
          <Col sm="7"> {/* Adjust the width as needed */}
            <label htmlFor="file-upload" className="custom-file-upload">
              <span>Choose File</span>
              <span className="file-name">{fileName}</span> {/* Display file name */}
            </label>
            <input
              id="file-upload"
              type="file"
              accept=".xml"
              onChange={handleFileChange}
              className="custom-file-input"
            />
            {fileError && <div className="text-danger">{fileError}</div>}
          </Col>
          <Col sm="3" className="d-flex align-items-center"> {/* Adjust the width as needed */}
            <Button type="submit" className="btn-custom" disabled={loading}>
              {loading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                  Uploading...
                </>
              ) : (
                "Upload"
              )}
            </Button>
          </Col>
        </Form.Group>

        {error && (
          <Alert variant="danger" onClose={clearError} dismissible>
            {error}
          </Alert>
        )}
        {successMessage && (
          <Alert variant="success" onClose={clearSuccessMessage} dismissible>
            {successMessage}
          </Alert>
        )}
        
      </Form>

      <Form className="mt-5 custom-form" onSubmit={handleSubmit1}>
        <Form.Group as={Row} controlId="formFil" className="mt-2">
          <Col sm="3" className="d-flex align-items-center"> {/* Adjust the width as needed */}
            <Button type="submit" className="btn-custom" disabled={loading}>
              {loading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                  login...
                </>
              ) : (
                "login"
              )}
            </Button>
          </Col>
        </Form.Group>

        {error && (
          <Alert variant="danger" onClose={clearError} dismissible>
            {error}
          </Alert>
        )}
        {successMessage && (
          <Alert variant="success" onClose={clearSuccessMessage} dismissible>
            {successMessage}
          </Alert>
        )}
        
      </Form>

      {/* Separate Form for Error Messages */}
      {errorMessages.length > 0 && (
        <Form className="mt-4 custom-form">
          <h2>Error Messages</h2>
          <ul>
            {errorMessages.map((claim, index) => (
              claim.errorMessages.length > 0 && (
                <div
                  key={index}
                  style={{ marginBottom: "10px", paddingBottom: "10px" }}
                >
                  <li style={{ marginBottom: "3px" }}>{`Claim: ${claim.claim}`}</li>
                  <ul>
                    {claim.errorMessages.map((error, errorIndex) => (
                      <li className="mb-3" key={errorIndex} style={{ color: "red" }}>
                        {error.id
                          ? `Code: ${error.id}, ${error.message}`
                          : `Code: ${error.message}`}
                      </li>
                    ))}
                  </ul>
                  {index < errorMessages.length - 1 && <hr />}
                </div>
              )
            ))}
          </ul>
        </Form>
      )}
    </Container>
  );
};

export default XmlValidationFile;
