import React from "react";
import { Container } from "react-bootstrap";

const Homepage = () => {
  return (
    <Container>
      {/* <h1>Home Page</h1>
      <p>Welcome to the home page!</p> */}
    </Container>
  );
};

export default Homepage;
