import { useState } from "react";
import axios from "axios";
import {baseUrl} from "../../utilities/baseUrl";

const useCreateUserFunction = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleCreateUser = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        `${baseUrl}/api/Auth/CreateUser`,
        { username, password },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setSuccess("User created successfully!");
        setUsername("");
        setPassword("");
      } else {
        setError("Failed to create user.");
      }
    } catch (err) {
      setError(
        err.response?.data?.message ||
        err.response?.data ||
        "An unknown error occurred."
      );
    }
  };

  return {
    username,
    setUsername,
    password,
    setPassword,
    handleCreateUser,
    error,
    success,
    setSuccess,
    setError,
  };
};

export default useCreateUserFunction;
