import React from "react";
import { Container, Form, Button, Row, Col, Alert, Spinner } from "react-bootstrap";
import overrideFunctions from "./overrideFunctions";
import "./overrideStyle.css";

const Override = () => {
  const {
    loading,
    error,
    clearError,
    handleSubmit,
    updateTypeError,
    fileError,
    fileName,
    uploading,
    uploadProgress,
    handleFileChange,
    successMessage,
    clearSuccessMessage,
    handleUpdateTypeChange,
  } = overrideFunctions();

  // URLs for download files
  const downloadLinks = {
    0: '/ValidFiles/Override_PDX_DRG.xlsx',
    1: '/ValidFiles/Override_CPT_DRG.xlsx',
    2: '/ValidFiles/Override_SDX_Severity.xlsx',
    3: '/ValidFiles/PDX_secondary_Dx_exclusion.xlsx',
  };

  return (
    <Container className="mb-5 modify">
      {uploading && (
        <div className="loading-overlay">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <div className="progress-container mt-3">
            <div
              className="progress-bar"
              style={{ width: `${uploadProgress}%` }}
            />
            <div className="progress-text" style={{ fontSize: '2rem' }}>{uploadProgress}%</div> {/* Larger font size */}
          </div>
        </div>
      )}
      <Form className="mt-5 custom-form" onSubmit={handleSubmit}>
        <h1>Override</h1>

        {/* Update Type Selection */}
        <Form.Group as={Row} controlId="formUpdateType" className="mt-2">
        <Form.Label column sm="2" className="form-label">
          Update Type:
        </Form.Label>
        <Col sm="10">
          <div className="d-flex align-items-center">
            <Form.Check
              inline
              type="radio"
              label="Principal diagnosis DRG"
              name="updateTypeRadio"
              id="option3Radio"
              value="0"
              onChange={(e) => handleUpdateTypeChange(e.target.value)}
              className="form-check"
            />
            <a
              href={downloadLinks[0]}
              download="Override_PDX_DRG.xlsx"
              target="_blank"
              rel="noopener noreferrer"
              className="ml-2"
            >
              <Button variant="outline-primary" size="sm">Download</Button>
            </a>
          </div>
          <div className="d-flex align-items-center mt-2">
            <Form.Check
              inline
              type="radio"
              label="Activity DRG"
              name="updateTypeRadio"
              id="option4Radio"
              value="1"
              onChange={(e) => handleUpdateTypeChange(e.target.value)}
              className="form-check"
            />
            <a
              href={downloadLinks[1]}
              download="Override_CPT_DRG.xlsx"
              target="_blank"
              rel="noopener noreferrer"
              className="ml-2"
            >
              <Button variant="outline-primary" size="sm">Download</Button>
            </a>
          </div>
          <div className="d-flex align-items-center mt-2">
            <Form.Check
              inline
              type="radio"
              label="Secondary Diagnosis Severity"
              name="updateTypeRadio"
              id="option5Radio"
              value="2"
              onChange={(e) => handleUpdateTypeChange(e.target.value)}
              className="form-check"
            />
            <a
              href={downloadLinks[2]}
              download="Override_SDX_Severity.xlsx"
              target="_blank"
              rel="noopener noreferrer"
              className="ml-2"
            >
              <Button variant="outline-primary" size="sm">Download</Button>
            </a>
          </div>
          <div className="d-flex align-items-center mt-2">
            <Form.Check
              inline
              type="radio"
              label="Secondary diagnosis excluded"
              name="updateTypeRadio"
              id="option6Radio"
              value="3"
              onChange={(e) => handleUpdateTypeChange(e.target.value)}
              className="form-check"
            />
            <a
              href={downloadLinks[3]}
              download="PDX_secondary_Dx_exclusion.xlsx"
              target="_blank"
              rel="noopener noreferrer"
              className="ml-2"
            >
              <Button variant="outline-primary" size="sm">Download</Button>
            </a>
          </div>
          {updateTypeError && <div className="text-danger mt-2">{updateTypeError}</div>}
        </Col>
      </Form.Group>
        

        {/* File Upload */}
        <Form.Group as={Row} controlId="formFile" className="mt-2">
          <Form.Label column sm="2" className="form-label">
            Upload File:
          </Form.Label>
          <Col sm="10">
            <label htmlFor="file-upload" className="custom-file-upload">
              <span>Choose File</span>
              <span className="file-name">{fileName}</span> {/* Display file name */}
            </label>
            <input
              id="file-upload"
              type="file"
              accept=".xlsx"
              onChange={handleFileChange}
              className="custom-file-input"
            />
            {fileError && <div className="text-danger">{fileError}</div>}
          </Col>
        </Form.Group>

        {/* Submit Button */}
        <Form.Group as={Row} className="mt-2 mb-2">
          <Col sm={{ span: 10, offset: 2 }}>
            <Button type="submit" className="btn-custom" disabled={loading}>
              {loading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                  Uploading...
                </>
              ) : (
                "Upload"
              )}
            </Button>
          </Col>
        </Form.Group>

        {error && (
          <Alert variant="danger" onClose={clearError} dismissible>
            {error}
          </Alert>
        )}
        {successMessage && (
          <Alert variant="success" onClose={clearSuccessMessage} dismissible>
            {successMessage}
          </Alert>
        )}
      </Form>
    </Container>
  );
};

export default Override;
