import React from "react";
import { Container, Form, Button, Row, Col, Alert } from "react-bootstrap";
import Select, { components } from "react-select";
import LoadingSpinner from "../../Components/loading-spinner";
import CreatePatientFunctions from "./createPatientFunctions";
import MenuList from "./menuList";
import "./style.css";
import check from "./validationInput";
import downloadFile from "./downloadFile";




const CreatePatient = () => {
  const {
    loading,
    error,
    clearError,
    dropdownData,
    setSelectedPdx,
    setSelectedSdx,
    setSelectedCpt,
    handleSubmit,
    loadingDrgCode,
    loadingAdviceCode,
    loadingValidationError,
    drgCode,
    adviceCode,
    validationError,
    drgTypeError,
    genderError,
    pdxError,
    setPdxError,
    ageError,
    handleGenderChange,
    handleDrgTypeChange,
    handleAgeChange,
    handleLengthOfStayChange,
    lengthOfStayError,
    setDrgExpectedCode,
    drgExpectedCode,
    weightError,
    handleWeightChange,
    resetForm,
  } = CreatePatientFunctions();



  const userRoles = JSON.parse(localStorage.getItem("userRoles")) || [];
  const isAdmin = userRoles.includes("Admin");

  return (
    <Container className="mb-5 modify">
      <Form className="mt-5 custom-form" onSubmit={handleSubmit}>
      <h1>Create New Patient</h1>
      
        {/* Gender Selection */}
        <Form.Group as={Row} controlId="formGender" className="mt-2">
          <Form.Label column sm="2">
            Gender
          </Form.Label>
          <Col sm="10">
            <Form.Check
              inline
              type="radio"
              label="Male"
              name="genderRadio"
              id="option8Radio"
              value="0"
              onChange={(e) => handleGenderChange(e.target.value)}
            />
            <Form.Check
              inline
              type="radio"
              label="Female"
              name="genderRadio"
              id="option9Radio"
              value="1"
              onChange={(e) => handleGenderChange(e.target.value)}
            />
            {genderError && <div className="text-danger">{genderError}</div>}
          </Col>
        </Form.Group>

        {/* Age Input Fields */}
        <Form.Group as={Row} controlId="formAge" className="mt-3">
          <Form.Label column sm="2">
            Age
          </Form.Label>
          <Col sm="10">
            <Row>
              <Col>
                <Form.Control
                  type="number"
                  step="1"
                  min="0"
                  placeholder="Years"
                  onChange={(e) => handleAgeChange('years', e.target.value)}
                />
              </Col>
              <Col>
                <Form.Control
                  type="number"
                  step="1"
                  min="0"
                  placeholder="Months"
                  onChange={(e) => handleAgeChange('months', e.target.value)}
                />
              </Col>
              <Col>
                <Form.Control
                  type="number"
                  step="1"
                  min="0"
                  placeholder="Days"
                  onChange={(e) => handleAgeChange('days', e.target.value)}
                />
              </Col>
            </Row>
            {ageError && <div className="text-danger">{ageError}</div>}
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mt-3">
          {/* Weight Input Field */}
          <Col sm="6">
            <Form.Label>Weight (kg)</Form.Label>
            <Form.Control
              type="number"
              step="0.1"
              min="0"
              placeholder="Enter weight"
              onChange={(e) => handleWeightChange(e.target.value)}
            />
            {weightError && <div className="text-danger">{weightError}</div>}
          </Col>

          {/* LengthOfStay Input Fields */}
          <Col sm="6">
            <Form.Label>Stay duration</Form.Label>
            <Row>
              <Col>
                <Form.Control
                  type="number"
                  step="1"
                  min="0"
                  placeholder="Days"
                  onChange={(e) => handleLengthOfStayChange(e.target.value)}
                />
              </Col>
            </Row>
            {lengthOfStayError && <div className="text-danger">{lengthOfStayError}</div>}
          </Col>
        </Form.Group>
       
        {/* DRG Type Selection */}
<Form.Group as={Row} controlId="formDRGType" className="mt-2 align-items-center">
  <Form.Label column sm="2">
    DRG Type
  </Form.Label>
  <Col sm="10">
    <div className="d-flex">
      {/* Inpatient Group */}
      <div className="mr-4">
        <fieldset>
          <legend className="col-form-label pt-0">Inpatient</legend>
          <Form.Check
            inline
            type="radio"
            label="IM"
            name="drgTypeRadio"
            id="option3Radio"
            value="0"
            onChange={(e) => handleDrgTypeChange(e.target.value)}
          />
          <Form.Check
            inline
            type="radio"
            label="IP"
            name="drgTypeRadio"
            id="option5Radio"
            value="2"
            onChange={(e) => handleDrgTypeChange(e.target.value)}
          />
        </fieldset>
      </div>

      {/* Ambulatory Group */}
      <div>
        <fieldset>
          <legend className="col-form-label pt-0">Ambulatory</legend>
          <Form.Check
            inline
            type="radio"
            label="AM"
            name="drgTypeRadio"
            id="option4Radio"
            value="1"
            onChange={(e) => handleDrgTypeChange(e.target.value)}
          />
          <Form.Check
            inline
            type="radio"
            label="AP"
            name="drgTypeRadio"
            id="option6Radio"
            value="3"
            onChange={(e) => handleDrgTypeChange(e.target.value)}
          />
        </fieldset>
      </div>
    </div>
    {drgTypeError && <div className="text-danger">{drgTypeError}</div>}
  </Col>
</Form.Group>



        {/* Dropdown Lists */}
        {dropdownData.map((dropdown, index) => (
          <Form.Group
            key={`dropdown${index}`}
            controlId={`formDropdown${index}`}
            as={Row}
            className="mt-3 position-relative"
          >
            <Form.Label column sm="2">
              {dropdown.label}
            </Form.Label>
            <Col sm="10">
              <Select
                options={dropdown.options}
                isSearchable
                isMulti={dropdown.isMulti}
                onInputChange={(value) => dropdown.onInputChange(value)}
                onChange={(selectedOptions) => {
                  if (dropdown.label === "PDX") {
                    setSelectedPdx(selectedOptions);
                    check(selectedOptions, setPdxError, "Please select a PDX code.");
                  } else if (dropdown.label === "SDX") {
                    setSelectedSdx(selectedOptions);
                  } else if (dropdown.label === "CPT") {
                    setSelectedCpt(selectedOptions);
                  }
                }}
                isLoading={loading}
                components={{
                  MenuList: (props) => (
                    <MenuList
                      {...props}
                      hasMore={dropdown.hasMore}
                      loadMore={dropdown.loadMore}
                    />
                  ),
                }}
              />
              {dropdown.showEmptySearchAlert && (
                <Alert
                  variant="warning"
                  className="mt-2 mb-2 alert-above-dropdown position-absolute"
                >
                  Please enter a search term to load more {dropdown.label}.
                </Alert>
              )}
                {dropdown.label === "PDX" && pdxError && (
                  <div className="text-danger">{pdxError}</div>
              )}
            </Col>
          </Form.Group>
        ))}

        {/* DRG Expected code Input */}
        <Form.Group as={Row} controlId="formDrgCode" className="mt-3">
          <Form.Label column sm="2">
            Expected DRG Code
          </Form.Label>
          <Col sm="10">
            <Form.Control
              type="text"
              value={drgExpectedCode}
              onChange={(e) => setDrgExpectedCode(e.target.value)}
            />
          </Col>
        </Form.Group>


        {/* Submit Button */}
        <Form.Group as={Row} className="mt-2">
          <Col sm={{ span: 10, offset: 2 }}>
            <Button type="submit" className="btn-custom" disabled={loading}>
              {loading ? "Submitting..." : "Submit"}
            </Button>
          </Col>
        </Form.Group>
      </Form>

      <Form className="mt-2 custom-form">
        <Form.Group as={Row} controlId="formDrgCode">
            <Form.Label column sm="2">
              DRG Code
            </Form.Label>
            <Col sm="10">
              {loadingDrgCode ? (
                <LoadingSpinner />
              ) : (
                <Form.Control type="text" value={drgCode.join(', ')} readOnly />
              )}
              {error && (
                <Alert
                  variant="danger"
                  className="mt-2"
                  onClose={clearError}
                  dismissible
                >
                  {error}
                </Alert>
              )}
            </Col>
        </Form.Group>

        {/* Advice Code Input with Loading Indicator */}
        <Form.Group as={Row} controlId="formAdviceCode" className="mt-3">
          <Form.Label column sm="2">
            Advice Code
          </Form.Label>
          <Col sm="10">
            {loadingAdviceCode ? (
              <LoadingSpinner />
            ) : (
              <ol className="advice-list">
                {adviceCode.map((advice, index) => (
                  <li key={index}>
                    {advice}
                  </li>
                ))}
              </ol>
            )}
            {error && (
              <Alert
                variant="danger"
                className="mt-2"
                onClose={clearError}
                dismissible
              >
                {error}
              </Alert>
            )}
          </Col>
        </Form.Group>

        {/* DRG Validation error Input with Loading Indicator */}
        <Form.Group as={Row} controlId="formValidation" className="mt-3">
          <Form.Label column sm="2">
            Validation Errors
          </Form.Label>
          <Col sm="10">
            {loadingValidationError ? (
              <LoadingSpinner />
            ) : (
              <ol className="advice-list">
                {validationError.map((vError, index) => (
                  <li key={index}>
                    {vError}
                  </li>
                ))}
              </ol>
            )}
            {error && (
              <Alert
                variant="danger"
                className="mt-2"
                onClose={clearError}
                dismissible
              >
                {error}
              </Alert>
            )}
          </Col>
        </Form.Group>

        {/* Download Button */}
        <Form.Group as={Row} className="mt-4">
          <Col sm={{ span: 10, offset: 2 }}>
            {isAdmin && (
              <Button
                className="btn-custom"
                onClick={downloadFile}
                disabled={loadingDrgCode || loadingAdviceCode}
              >
                {loadingDrgCode || loadingAdviceCode ? "Loading..." : "Download DRG Results"}
              </Button>
            )}
          </Col>
        </Form.Group>

      </Form>
    </Container>
  );
};

export default CreatePatient;
