import React, { useEffect } from 'react';
import { Navbar as BootstrapNavbar, Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FaUser, FaSignOutAlt, FaBars } from 'react-icons/fa';
import './style.css';

const Navbar = ({ setIsAuthenticated }) => {
  const navigate = useNavigate();
  const userRoles = JSON.parse(localStorage.getItem("userRoles")) || [];

  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector('.navbar-container');
      if (window.scrollY > 50) {
        navbar.classList.add('scrolled');
      } else {
        navbar.classList.remove('scrolled');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('authenticated');
    localStorage.removeItem('token');
    localStorage.removeItem('userRoles');
    setIsAuthenticated(false);
    navigate('/login');
  };

  return (
    <BootstrapNavbar bg="dark" variant="dark" expand="lg" className="navbar-container">
      <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" className="navbar-toggler-right">
        <FaBars />
      </BootstrapNavbar.Toggle>
      <BootstrapNavbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          {userRoles.includes("Admin") && (
            <Nav.Link className="nav-link" onClick={() => navigate('/createUser')}>
              <FaUser /> Create User
            </Nav.Link>
          )}
          <Nav.Link className="nav-link" onClick={handleLogout}>
            <FaSignOutAlt /> Logout
          </Nav.Link>
        </Nav>
      </BootstrapNavbar.Collapse>
    </BootstrapNavbar>
  );
};

export default Navbar;
