import { useState, useEffect, useRef } from"react";
import axios from"axios";
import { toast } from"react-toastify";

const useAuthInterceptor = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const cancelTokenSourceRef = useRef(null);

  useEffect(() => {
    const authStatus = localStorage.getItem("authenticated");

    if (authStatus === "true") {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
    
    setIsAuthChecked(true);
    
    const axiosInterceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401 && isAuthenticated) {

          // Cancel all pending requests
          if (cancelTokenSourceRef.current) {
            cancelTokenSourceRef.current.cancel("Session expired, canceling all requests.");
          }

          toast.error("Session expired. Please log in again.");
          localStorage.removeItem("authenticated");
          localStorage.removeItem("access_token");
          localStorage.removeItem("userRoles");
          setIsAuthenticated(false);
           setTimeout(() => {
          //   window.location.href = "/login";
           }, 1); // 3 seconds delay before redirecting to login
        }
        return Promise.reject(error);
      }
    );

    return() => {
      axios.interceptors.response.eject(axiosInterceptor);
    };
  }, [isAuthenticated]);

  const createCancelToken = () => {
    cancelTokenSourceRef.current = axios.CancelToken.source();
    return cancelTokenSourceRef.current.token;
  };

  return { isAuthenticated, setIsAuthenticated, isAuthChecked, createCancelToken };
};

export default useAuthInterceptor;
