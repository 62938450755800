import { components } from "react-select";
import { Button } from "react-bootstrap";
import { useRef, useEffect } from "react";

// Custom MenuList to include the Load More button and maintain scroll position
export const MenuList = (props) => {
    const menuRef = useRef(null); // Reference to the menu container
    const prevOptionsLength = useRef(props.options.length); // Track previous number of options

    const handleLoadMore = () => {
        if (props.hasMore) {
            // Call the loadMore function from props
            props.loadMore();
        }
    };

    useEffect(() => {
        const menu = menuRef.current;

        if (menu) {
            // Calculate the height of items that are currently visible
            const visibleItemHeight = menu.scrollHeight / props.options.length;

            // Scroll to position where the new items are visible
            if (props.options.length > prevOptionsLength.current) {
                const newItemsHeight = (props.options.length - prevOptionsLength.current) * visibleItemHeight;
                menu.scrollTop += newItemsHeight;
            }
        }

        // Update the ref for the next render
        prevOptionsLength.current = props.options.length;
    }, [props.options]); // Update when options change (new items loaded)

    return (
        <components.MenuList {...props} innerRef={menuRef}>
            {props.children}
            {props.hasMore && (
                <div style={{ textAlign: "center", marginTop: "10px" }}>
                    <Button onClick={handleLoadMore} size="sm">
                        Load More
                    </Button>
                </div>
            )}
        </components.MenuList>
    );
};

export default MenuList;
