import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import debounce from "lodash.debounce";
import {baseUrl} from "../../utilities/baseUrl";
import check from "./validationInput";

const CreatePatientFunctions = () => {
  const [pdxCodes, setPdxCodes] = useState([]);
  const [sdxCodes, setSdxCodes] = useState([]);
  const [CptCodes, setCptCodes] = useState([]);
  const [pdxSearchTerm, setPdxSearchTerm] = useState("");
  const [sdxSearchTerm, setSdxSearchTerm] = useState("");
  const [CptSearchTerm, setCptSearchTerm] = useState("");
  const [pdxPageIndex, setPdxPageIndex] = useState(1);
  const [sdxPageIndex, setSdxPageIndex] = useState(1);
  const [CptPageIndex, setCptPageIndex] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMorePdx, setHasMorePdx] = useState(true);
  const [hasMoreSdx, setHasMoreSdx] = useState(true);
  const [hasMoreCpt, setHasMoreCpt] = useState(true);
  const [showEmptySearchAlertPdx, setShowEmptySearchAlertPdx] = useState(false);
  const [showEmptySearchAlertSdx, setShowEmptySearchAlertSdx] = useState(false);
  const [showEmptySearchAlertCpt, setShowEmptySearchAlertCpt] = useState(false);
  const [selectedPdx, setSelectedPdx] = useState(null);
  const [selectedSdx, setSelectedSdx] = useState([]);
  const [selectedCpt, setSelectedCpt] = useState([]);
  const [drgType, setDrgType] = useState(null);
  const [gender, setGender] = useState(null);
  const [drgCode, setDrgCode] = useState([]);
  const [adviceCode, setAdviceCode] = useState([]);
  const [validationError, setValidationError] = useState([]);
  const [loadingDrgCode, setLoadingDrgCode] = useState(false); // New loading state for DRG code
  const [loadingAdviceCode, setLoadingAdviceCode] = useState(false); // New loading state for Advice code
  const [loadingValidationError, setLoadingValidationError] = useState(false); // New loading state for Advice code
  const [error, setError] = useState(null); // State for managing API error
  const [drgTypeError, setDrgTypeError] = useState(null); // State for managing DRG type selection error
  const [genderError, setGenderError] = useState(null); // State for managing DRG type selection error
  const [pdxError, setPdxError] = useState(null); // State for managing DRG type selection error
  const [ageDays, setAgeDays] = useState(0);
  const [ageMonths, setAgeMonths] = useState(0);
  const [ageYears, setAgeYears] = useState(0);
  const [lengthOfStay, setLengthOfStay] = useState(0);
  const [ageError, setAgeError] = useState(null); // State for managing age selection error
  const [lengthOfStayError, setLengthOfStayError] = useState(null); // State for managing age selection error
  const [drgExpectedCode, setDrgExpectedCode] = useState('');
  const [weight, setWeight] = useState(0);
  const [weightError, setWeightError] = useState(null); // State for managing age selection error
  const [isNewborn, setIsNewborn] = useState(false);
  


  useEffect(() => {
    fetchPdxCodes("", 1); // Fetch all PDX codes initially
    fetchSdxCodes("", 1); // Fetch all SDX codes initially
    fetchCptCodes("", 1); // Fetch all Cpt codes initially
  }, []);

  useEffect(() => {
    if (pdxSearchTerm !== "") {
      debouncedFetchPdxCodes(pdxSearchTerm, 1);
    }
  }, [pdxSearchTerm]);

  useEffect(() => {
    if (sdxSearchTerm !== "") {
      debouncedFetchSdxCodes(sdxSearchTerm, 1);
    }
  }, [sdxSearchTerm]);

  useEffect(() => {
    if (CptSearchTerm !== "") {
      debouncedFetchCptCodes(CptSearchTerm, 1);
    }
  }, [CptSearchTerm]);

  const fetchPdxCodes = async (searchTerm, pageIndex) => {
    try {
      setLoading(true);
      const response = await axios.get(`${baseUrl}/api/Diagnosis`, {
        params: {
          PageIndex: pageIndex,
          PageSize: 50, // Reduced page size for faster load
          Search: searchTerm,
        },
      });
      const data = response?.data.data;
  
      setPdxCodes((prevPdxCodes) => {
        if (pageIndex === 1) {
          return data;
        }
        const newCodes = [...prevPdxCodes, ...data];
        const uniqueCodes = Array.from(new Set(newCodes.map((code) => code.id))).map(
          (id) => newCodes.find((code) => code.id === id)
        );
        return uniqueCodes;
      });
  
      setHasMorePdx(data.length > 0);
    } catch (error) {
      console.error("Error fetching PDX data:", error);
    } finally {
      setLoading(false);
    }
  };
  
  const fetchSdxCodes = async (searchTerm, pageIndex) => {
    try {
      setLoading(true);
      const response = await axios.get(`${baseUrl}/api/Diagnosis`, {
        params: {
          PageIndex: pageIndex,
          PageSize: 50, // Reduced page size for faster load
          Search: searchTerm,
        },
      });
      const data = response.data.data;
  
      setSdxCodes((prevSdxCodes) => {
        if (pageIndex === 1) {
          return data;
        }
        const newCodes = [...prevSdxCodes, ...data];
        const uniqueCodes = Array.from(new Set(newCodes.map((code) => code.id))).map(
          (id) => newCodes.find((code) => code.id === id)
        );
        return uniqueCodes;
      });
  
      setHasMoreSdx(data.length > 0);
    } catch (error) {
      console.error("Error fetching SDX data:", error);
    } finally {
      setLoading(false);
    }
  };
  
  const fetchCptCodes = async (searchTerm, pageIndex) => {
    try {
      setLoading(true);
      const response = await axios.get(`${baseUrl}/api/Activities`, {
        params: {
          PageIndex: pageIndex,
          PageSize: 50, // Reduced page size for faster load
          Search: searchTerm,
        },
      });
      const data = response.data.data;
  
      setCptCodes((prevCptCodes) => {
        if (pageIndex === 1) {
          return data;
        }
        const newCodes = [...prevCptCodes, ...data];
        const uniqueCodes = Array.from(new Set(newCodes.map((code) => code.id))).map(
          (id) => newCodes.find((code) => code.id === id)
        );
        return uniqueCodes;
      });
  
      setHasMoreCpt(data.length > 0);
    } catch (error) {
      console.error("Error fetching CPT data:", error);
    } finally {
      setLoading(false);
    }
  };
  
  

  const debouncedFetchPdxCodes = useCallback(debounce(fetchPdxCodes, 1000), []);
  const debouncedFetchSdxCodes = useCallback(debounce(fetchSdxCodes, 1000), []);
  const debouncedFetchCptCodes = useCallback(debounce(fetchCptCodes, 1000), []);

  const handlePdxSearchChange = (inputValue) => {
    setPdxSearchTerm(inputValue);
    setPdxPageIndex(1); // Reset page index when search term changes
    setHasMorePdx(true); // Reset hasMore flag
    setShowEmptySearchAlertPdx(false); // Reset empty search alert
  };

  const handleSdxSearchChange = (inputValue) => {
    setSdxSearchTerm(inputValue);
    setSdxPageIndex(1); // Reset page index when search term changes
    setHasMoreSdx(true); // Reset hasMore flag
    setShowEmptySearchAlertSdx(false); // Reset empty search alert
  };

  const handleCptSearchChange = (inputValue) => {
    setCptSearchTerm(inputValue);
    setSdxPageIndex(1); // Reset page index when search term changes
    setHasMoreSdx(true); // Reset hasMore flag
    setShowEmptySearchAlertCpt(false); // Reset empty search alert
  };

  const loadMorePdx = () => {
    if (pdxSearchTerm !== "") {
      const nextPage = pdxPageIndex + 1;
      setPdxPageIndex(nextPage);
      fetchPdxCodes(pdxSearchTerm, nextPage);
    } else {
      setShowEmptySearchAlertPdx(true); // Show alert for PDX
    }
  };

  const loadMoreSdx = () => {
    if (sdxSearchTerm !== "") {
      const nextPage = sdxPageIndex + 1;
      setSdxPageIndex(nextPage);
      fetchSdxCodes(sdxSearchTerm, nextPage);
    } else {
      setShowEmptySearchAlertSdx(true); // Show alert for SDX
    }
  };

  const loadMoreCpt = () => {
    if (CptSearchTerm !== "") {
      const nextPage = CptPageIndex + 1;
      setCptPageIndex(nextPage);
      fetchCptCodes(CptSearchTerm, nextPage);
    } else {
      setShowEmptySearchAlertCpt(true); // Show alert for SDX
    }
  };

  const handleGenderChange = (value) => {
    setGender(value);
    setGenderError(null); // Clear the error message when gender is selected
  };

  const handleDrgTypeChange = (value) => {
    setDrgType(value);
    setDrgTypeError(null); // Clear the error message when DRG type is selected
  };

  const handleLengthOfStayChange = (value) => {
    setLengthOfStay(value);
    setLengthOfStayError(null); // Clear the error message when DRG type is selected
  };

  const handleWeightChange = (value) => {
    const parsedValue = parseFloat(value) || 0;
    setWeight(parsedValue);
  
    if (isNewborn && (parsedValue === 0 || isNaN(parsedValue))) {
      setWeightError("Weight field is required for newborns (≤7 days old).");
    } else {
      setWeightError(null);
    }
  };

  const handleAgeChange = (type, value) => {
    const parsedValue = parseInt(value, 10) || 0;
  
    if (type === 'days') {
      setAgeDays(parsedValue);
    } else if (type === 'months') {
      setAgeMonths(parsedValue);
    } else if (type === 'years') {
      setAgeYears(parsedValue);
    }
  };

  useEffect(() => {
    const newbornStatus = ageYears === 0 && ageMonths === 0 && ageDays <= 7;
    setIsNewborn(newbornStatus);
  
    // Validate weight whenever isNewborn changes
    if (newbornStatus && (!weight || weight === 0)) {
      setWeightError("Weight field is required for newborns (≤7 days old).");
    } else {
      setWeightError(null);
    }
  }, [ageYears, ageMonths, ageDays, weight]);


  const handleSubmit = async (event) => {

    
    event.preventDefault();
    const isNewborn = ageYears === 0 && ageMonths === 0 && ageDays <= 7;
    // Check if any error messages are set
    switch (true) {
      case gender === null:
        check(gender, setGenderError, "Please select a gender.");
          return;
        case !ageDays && !ageMonths && !ageYears:
          check(false, setAgeError, "Please enter at least one age component.");
          return;
          case (isNewborn && (weight === 0 || isNaN(weight))):
            check(false, setWeightError, "Weight field is required for newborns (≤7 days old).");
            return;  
        case !lengthOfStay:
          check(false, setLengthOfStayError, "Please enter length of stay.");
          return;
        case drgType === null:
        check(drgType, setDrgTypeError, "Please select a DRG type.");
        return;
        case selectedPdx === null:
          check(selectedPdx, setPdxError, "Please select a PDX code.");
          return;
          default:
            setDrgTypeError(null);
          setPdxError(null);
        }
        try {

      const token = localStorage.getItem("token");

      setLoading(true); // Set overall loading state
      const pdxId = selectedPdx ? selectedPdx.value : null;
      const sdxIds = selectedSdx.map((sdx) => sdx.value);
      const cptIds = selectedCpt.map((cpt) => cpt.value);
      const drgTypeInt = parseInt(drgType); // Convert drgType to integer
      const genderInt = parseInt(gender); // Convert gender to integer
      const lengthOfStayInt = parseInt(lengthOfStay); // Convert gender to integer

      setLoadingDrgCode(true); // Set loading state for DRG code
      setLoadingAdviceCode(true); // Set loading state for Advice code
      setLoadingValidationError(true); // Set loading state for Advice code

      const response = await axios.post(`${baseUrl}/api/PatientDiagnosis`, {
        principalDiagnosisId: pdxId,
        secondaryDiagnosisIds: sdxIds,
        activitiesIds: cptIds,
        drgType: drgTypeInt, // Send as integer
        gender: genderInt, // Send as integer
        age: {
          days: ageDays ? parseInt(ageDays) : 0,
          months: ageMonths ? parseInt(ageMonths) : 0,
          years: ageYears ? parseInt(ageYears) : 0,
        },
        lengthOfStay: lengthOfStayInt,
        expectedDRG: drgExpectedCode,
        weight: weight ? parseFloat(weight) : 0,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

      const data = response.data;


      if (data.drgList.length > 0) {
        const drgCodes = data.drgList.map(item => item.code);
        setDrgCode(drgCodes); 
      } else {
        setDrgCode(["No DRG code found!"]);
        console.warn("No DRG code found in the response.");
      }
      // Set advice codes
      const adviceCodes = data.adviceList.length > 0 ? data.adviceList : ["No advice codes found!"];
      setAdviceCode(adviceCodes);

      // Set validation error
      const validationErrors = data.validationErrors.length > 0 ? data.validationErrors : ["No validation Errors found!"];
      setValidationError(validationErrors);

      setError(null);
    } catch (error) {
      console.error("Error submitting form:", error);
      setDrgCode([]);
      setAdviceCode([]);
      setValidationError([]);
      if (error.response?.status === 401){
        setError("Your session has expired. Please log in again to continue.");
      }else{
        setError(error.response?.data?.message || error.response?.data ||"An unknown error occurred.");
      }
    } finally {
      setLoading(false); // Reset overall loading state
      setLoadingDrgCode(false); // Reset loading state for DRG code
      setLoadingAdviceCode(false);
      setLoadingValidationError(false);
    }
  };

  const clearError = () => {
    setError(null); // Clear error when dismissed by user
  };

  const dropdownData = [
    {
      label: "PDX",
      options: pdxCodes.map((code) => ({
        value: code.id,
        label: `${code.code} - ${code.description}`,
      })),
      isMulti: false,
      onInputChange: handlePdxSearchChange,
      hasMore: hasMorePdx,
      loadMore: loadMorePdx,
      showEmptySearchAlert: showEmptySearchAlertPdx,
    },
    {
      label: "SDX",
      options: sdxCodes.map((code) => ({
        value: code.id,
        label: `${code.code} - ${code.description}`,
      })),
      isMulti: true,
      onInputChange: handleSdxSearchChange,
      hasMore: hasMoreSdx,
      loadMore: loadMoreSdx,
      showEmptySearchAlert: showEmptySearchAlertSdx,
    },
    {
      label: "CPT",
      options: CptCodes.map((code) => ({
        value: code.id,
        label: `${code.code} - ${code.description}`,
      })),
      isMulti: true,
      onInputChange: handleCptSearchChange,
      hasMore: hasMoreCpt,
      loadMore: loadMoreCpt,
      showEmptySearchAlert: showEmptySearchAlertCpt,
    },
  ];


  const resetForm = () => {
    setPdxSearchTerm("");
    setSdxSearchTerm("");
    setCptSearchTerm("");
    setPdxPageIndex(1);
    setSdxPageIndex(1);
    setCptPageIndex(1);
    setHasMorePdx(true);
    setHasMoreSdx(true);
    setHasMoreCpt(true);
    setSelectedPdx(null);
    setSelectedSdx([]);
    setSelectedCpt([]);
    setDrgType(null);
    setGender(null);
    setDrgCode([]);
    setAdviceCode([]);
    setValidationError([]);
    setAgeDays(0);
    setAgeMonths(0);
    setAgeYears(0);
    setLengthOfStay(0);
    setWeight(0);
    setError(null);
    setDrgTypeError(null);
    setGenderError(null);
    setPdxError(null);
    setAgeError(null);
    setLengthOfStayError(null);
    setWeightError(null);
    setShowEmptySearchAlertPdx(false);
    setShowEmptySearchAlertSdx(false);
    setShowEmptySearchAlertCpt(false);
  };

  return {
    loading,
    error,
    clearError,
    dropdownData,
    setDrgType,
    setGender,
    setSelectedPdx,
    setSelectedSdx,
    setSelectedCpt,
    handleSubmit,
    loadingDrgCode,
    loadingAdviceCode,
    loadingValidationError,
    drgCode,
    adviceCode,
    validationError,
    drgTypeError,
    genderError,
    pdxError,
    setPdxError,
    setAgeDays,
    setAgeMonths,
    setAgeYears,
    ageError,
    handleGenderChange,
    handleDrgTypeChange,
    handleAgeChange,
    handleLengthOfStayChange,
    lengthOfStayError,
    setDrgExpectedCode,
    drgExpectedCode,
    weight,
    weightError,
    handleWeightChange,
    isNewborn,
    resetForm,
  };
};

export default CreatePatientFunctions;
