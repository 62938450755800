import React from "react";
import { Spinner } from "react-bootstrap";
import "./style.css";

const LoadingSpinner = () => {
  return (
    <div className="loading-wrapper">
      <Spinner animation="border" role="status"></Spinner>
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export default LoadingSpinner;
