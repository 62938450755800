import { useState } from "react";
import axios from "axios";
import {baseUrl2} from "../../utilities/baseUrl";

const XmlValidationFileFunction = () => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null); // State for the file
  const [error, setError] = useState(null); // State for managing API error
  const [fileError, setFileError] = useState(null); // State for managing file selection error
  const [fileName, setFileName] = useState(""); // State to hold the file name
  const [uploading, setUploading] = useState(false); // State to handle loading
  const [uploadProgress, setUploadProgress] = useState(0); // State for upload progress
  const [successMessage, setSuccessMessage] = useState(null); // State for success message
  const [errorMessages, setErrorMessages] = useState([]);




  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    setFile(file);
    setFileName(file ? file.name : "No file chosen");
    setUploading(true); // Show loading when file is selected

    const formData = new FormData();
    formData.append("file", file);

    try {
      // Simulate file upload with progress tracking
      await axios.post(`/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percent);
        },
      });
      setUploadProgress(100); // Set to 100% when done
    } catch (error) {
      console.error(error);
    } finally {
      setUploading(false); // Hide loading after upload
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!file) {
      setFileError("Please select a file.");
      return;
    } else {
      setFileError(null);
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTIsImlhdCI6MTcyNzA3NTMwMSwiZXhwIjoxNzI4ODA0MTc2OTIyfQ.yLXAXM6KI8sYLWzoJNl8NVyrZibH-rrT2hkUAhydKyo";
      setLoading(true);
      //  console.log("before!!!!!!!!!!!!");
      const response1 = await axios.post(`${baseUrl2}/auth/login`, {
        username: "testu-7-7",
        password: "TestU-7-7@123"
      });
      const token1 = response1.data.data.token;
      // alert("Before login request"+ token1);

      const response = await axios.post(`${baseUrl2}/validate/xml`, formData, {
        headers: {
          Authorization: `Bearer ${token1}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      const data = response.data;

      // Check if data is an array and contains error messages
      if (data.length > 0) {
        setSuccessMessage("File uploaded successfully!"); // Set success message
        setErrorMessages(data); // Set the error messages from the response
        setTimeout(() => setSuccessMessage(null), 5000);
      } else {
        setSuccessMessage("File uploaded successfully, but no errors found!"); // If no errors, still show success
        setErrorMessages([]); // Clear previous error messages
      }

      setError(null);
    } catch (error) {
      console.error("Error submitting form:", error);
      setError(error.response?.data || "An error occurred."); // Set error message in state
  
      // Handle unexpected errors
      setErrorMessages([{ message: "An unexpected error occurred." }]);
    } finally {
      setLoading(false); // Reset overall loading state
    }
};

const handleSubmit1 = async (event) => {
  event.preventDefault();

  try {
    // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTIsImlhdCI6MTcyNzA3NTMwMSwiZXhwIjoxNzI4ODA0MTc2OTIyfQ.yLXAXM6KI8sYLWzoJNl8NVyrZibH-rrT2hkUAhydKyo";
    setLoading(true);
    //  console.log("before!!!!!!!!!!!!");
    const response1 = await axios.post(`${baseUrl2}/auth/login`, {
      username: "testu-7-7",
      password: "TestU-7-7@123"
    });
    const token1 = response1.data.data.token;
    // alert("Before login request"+ token1);
    return token1;

  } catch (error) {
    console.error("Error submitting form:", error);
    setError(error.response?.data || "An error occurred."); // Set error message in state

    // Handle unexpected errors
    setErrorMessages([{ message: "An unexpected error occurred." }]);
  } finally {
    setLoading(false); // Reset overall loading state
  }
};


  const clearError = () => {
    setError(null); // Clear error when dismissed by user
  };

  const clearSuccessMessage = () => {
    setSuccessMessage(null); // Clear success message when dismissed by user
  };

  return {
    loading,
    error,
    successMessage, // Return success message
    clearError,
    clearSuccessMessage, // Expose clearSuccessMessage function
    handleSubmit,
    setFile, // Expose setFile function
    fileError, // Expose fileError state
    fileName,
    uploading,
    uploadProgress,
    handleFileChange,
    errorMessages,
    handleSubmit1,
  };
};

export default XmlValidationFileFunction;
