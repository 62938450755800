import React from "react";
import LoginFunction from "./loginFunction";
import "./style.css"


const Login = ({ setIsAuthenticated }) => {
    const {
      loginName,
      setLoginName,
      loginPassword,
      setLoginPassword,
      handleLogin,
      error,
    } = LoginFunction(setIsAuthenticated);
  
    return (
      <div className="login-container">
        <form className="login-form" onSubmit={handleLogin}>
          <h2>Login</h2>
          <div>
            <label>Username:</label>
            <input
              type="text"
              value={loginName}
              onChange={(e) => setLoginName(e.target.value)}
            />
          </div>
          <div>
            <label>Password:</label>
            <input
              type="password"
              value={loginPassword}
              onChange={(e) => setLoginPassword(e.target.value)}
            />
          </div>
          {error && <p>{error}</p>}
          <button type="submit">Login</button>
        </form>
      </div>
    );
  };
  
  export default Login;
